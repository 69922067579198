import React from "react";

// COMPONENT
import { TermsAndConditionsLayout } from "../../../layouts/TermsAndConditions";

const conditions = [
  {
    serial_no: "i",
    value: `No Endorsement:The Company does not endorse any Influencer, nor does it make direct endorsement claims or provide representations or guarantees regarding the performance, quality, or behaviour of any Influencer.
    `,
  },
  {
    serial_no: "ii",
    value: `No Employment: The Influencers are not employees of the Company; they are Users who have registered on the Platform to participate in Campaigns. Any claims the Brand wishes to initiate, or any legal remedies the Brand wishes to avail against an Influencer, will be limited to claims against the specific Influencer or other third parties responsible for causing harm to the Brand. The Brand agrees not to impose liability on the Company or seek any legal remedy from the Company regarding such actions or omissions.

    `,
  },
  {
    serial_no: "iii",
    value: (
      <div className="flex flex-col">
        <div>Campaign Description and Deliverable:</div>
        <div className="mt-1">
          <div>
            a) The Brand represents and warrants that it owns all content shared
            in the Campaign or possesses all necessary rights to grant the
            Company the license to use such content under the Terms and
            Conditions. The Brand also represents and warrants that neither the
            content shared in the Campaign nor the use and provision of such
            content by the Company or the Influencer will infringe,
            misappropriate, or violate any third party's intellectual property
            rights, rights of publicity or privacy, or result in any violation
            of applicable laws or regulations, including export control laws.
          </div>
          <div className="mt-1">
            b) The Brand will strive to be as clear and explicit about the
            Campaign Deliverables while sharing the Campaign brief with the
            Influencer and while creating a Campaign description. The Company
            and the Influencer will not be liable in the event the Campaign
            Deliverables do not reflect the Brand’s requirement if there is an
            error or miscommunication from the Brand with respect to the
            expected Campaign Deliverables.
          </div>
          <div className="mt-1">
            c) The Brand acknowledges, agrees, and accepts that the Company may
            use the Deliverables for the purposes of marketing, advertising, and
            promoting the Company's marketplace to third parties and the general
            public. This includes, but is not limited to, showcasing the
            Deliverables in promotional materials, case studies, advertisements,
            and on the Company's website or other platforms.
          </div>
        </div>
      </div>
    ),
  },
  {
    serial_no: "iv",
    value: `Campaign Cancellation & Refunds:Once a campaign is initiated by the brand and accepted by the creator, it cannot be cancelled. Any Refunds (if applicable) will be processed within 07 (seven) working days and the credited amount will reflect in the customer's bank account. 

    `,
  },
  {
    serial_no: "v",
    value: (
      <div className="flex flex-col">
        <div>Relationship between the Influencer and the Brand</div>
        <div className="mt-1">
          <div>
            a) The Brand must at all times transact in good faith with the
            Influencer. The Brand will endeavour to cordially resolve any issues
            and refrain from taking any legal recourse without an attempt for
            amicable resolution.
          </div>
          <div className="mt-1">
            b) Brand shall not require the Influencer to make any claims
            relating to the quality, efficacy or any other quantitative or
            qualitative aspect of the Campaign Subject Matter that has not been
            verified or is true, accurate and can be proven with sufficient
            evidence.
          </div>
          <div className="mt-1">
            c) The Brand represents that it has documents to support the claims
            that the Influencer is required to make in the Campaign Deliverable
            with respect to the Campaign Subject Matter and the Brand shall
            share the same with the Influencer at the time of sharing details
            regarding the Campaign. The Company and the Influencer are relying
            on this representation made by the Brand and shall not be liable for
            any claims arising in this regard.
          </div>
        </div>
      </div>
    ),
  },
  {
    serial_no: "vi",
    value: (
      <div className="flex flex-col">
        <div>Campaign Subject Matter and ASCI Regulatory Compliance</div>
        <div className="mt-1">
          <div>
            a) The Brand shall ensure the delivery of Campaign Subject Matter
            like items, props to the Influencer. It is the brand's
            responsibility to ensure that the deliverables are provided to the
            influencer in a timely manner for successful delivery of the
            Campaign.
          </div>
          <div className="mt-1">
            b) The Brand while providing/approving content, using or exploiting
            the Campaign Materials shall adhere to all the applicable
            advertising laws including but not limited to Advertising Standard
            Council of India rules, guidelines in existence or which may come
            into existence in future relating to digital marketing. The Brand
            shall solely be liable for any claims arising in this regard.
          </div>
          <div className="mt-1">
            c) The Brand shall disclose all material information and provide the
            appropriate labels that the Influencer has to use in the Campaign
            Material for complying with applicable guidelines including but not
            limited to ASCI guidelines for digital/influencer marketing or such
            other guidelines and laws applicable within the jurisdiction where
            the Campaign has to be released.
          </div>
          <div className="mt-1">
            d) In case of any issue/claim raised in the Campaign Deliverable for
            the purpose of advertising, promotions, marketing in the Campaign
            then the Brand shall at its own cost, expense and risk and
            responsibility promptly defend, pay and settle all such third-party
            claims and actions including claims raised by ASCI and or any
            governing and/or non-governing bodies in such manner as Brand may
            deem fit without any liability on the Company and/or the Influencer.
          </div>
        </div>
      </div>
    ),
  },
  {
    serial_no: "vii",
    value: `Third Party Services
    : The Brand acknowledges and agrees that the Platform provided by the Company may be subject to interruptions, delays, or limitations due to issues arising from third-party providers. The Company shall not be liable for any disruptions, modifications, suspensions, or terminations of third-party services that affect the performance or delivery of Campaign under this Agreement. In the event of such disruptions, the Brand agrees to allow a buffer period of Fourteen (14) days to the Company to resolve the issue, during which no penalties, claims, or consequences shall be imposed on the Company for any failure or delay in performance resulting from such third-party disruptions.
    `,
  },
  {
    serial_no: "viii",
    value: (
      <div className="flex flex-col">
        <div>Compliance with Laws and Data Protection</div>
        <div className="mt-1">
          a) The Brand represents and warrants that all data shared with the
          Company is legally sourced and fully compliant with all applicable
          data protection laws. The Brand further warrants that they shall not
          share, process, or disclose any data without first obtaining valid and
          lawful consent from the data subject. The Company shall not be liable
          for any breach of data privacy obligations resulting from the Brand
          failure to comply with such data protection laws. The Brand agrees to
          indemnify and hold the Company harmless from any and all claims,
          losses, penalties, or other liabilities arising from non-compliance
          with data protection laws, privacy obligations, or any failure to
          obtain proper consent as required by applicable laws.
        </div>
        <div className="mt-1">
          b) The Brand agrees to comply with all applicable telecom regulations,
          including but not limited to the National Do Not Call Register
          (NDNCR), the National Customer Preference Register (NCPR), and any
          other similar consumer preference-based do-not-disturb database or
          register. The Brand represents and warrants that: (if) all recipients
          of promotional or transactional messages or calls have provided valid
          prior consent as required by applicable laws, and (ii) no unsolicited
          commercial communication will be sent to recipients registered under
          any do-not-disturb (DND) databases, except as expressly permitted by
          law. The Brand agrees to indemnify, defend, and hold harmless the
          Company against any liabilities, penalties, claims, losses (including
          legal fees), or other expenses arising from non-compliance with
          applicable telecom regulations by the Brand or their authorized
          representatives.
        </div>
        <div className="mt-1">
          c) The Brand acknowledges and agrees that all communications,
          including calls and messages, facilitated by or through the Company’s
          platform must fully comply with all applicable laws, regulations, and
          industry standards, including but not limited to laws governing Do Not
          Disturb (DND) registers, consumer preferences, and the requirement to
          obtain prior consent from recipients where applicable. The Brand shall
          be solely responsible for ensuring that all communications are
          compliant with these legal requirements and shall bear full
          responsibility for any violations of such laws or regulations.
        </div>
        <div className="mt-1">
          d) The Brand warrants and undertakes that it has obtained all
          necessary, valid consents from the recipients of its communications
          and has ensured that such communications comply with any legal
          exceptions or exemptions that may apply under applicable laws,
          including those permitting contact with recipients registered on DND
          or similar databases. The Brand further undertakes to maintain
          accurate records of such consents and will provide proof of consent to
          the Company upon request.
        </div>
        <div className="mt-1">
          e) The Brand agrees to indemnify, defend, and hold harmless the
          Company, its affiliates, officers, employees, and agents from and
          against any and all claims, liabilities, penalties, damages, losses,
          expenses (including legal fees), or any other costs arising from: (if)
          any violation of applicable laws or regulations by the Brand or its
          Authorized Users in relation to such communications, (ii) failure to
          obtain necessary consents or non-compliance with DND or similar
          regulations, and (iii) any complaints, disputes, or actions initiated
          by recipients of the communications. This indemnity obligation shall
          be absolute and shall remain in full force and effect even after the
          termination or expiration of any agreement between the parties.
        </div>
        <div className="mt-1">
          f) The Brand acknowledges that the Company relies on third-party
          service providers, to execute its services. The Company shall not be
          liable for any delay, failure, or loss arising from force majeure
          events or disruptions caused by third-party services. In the event of
          such disruptions, the Brand agrees to bear any additional costs or
          liabilities arising from any necessary adjustments or alternative
          measures required to mitigate the impact of such disruptions.
        </div>
        <div className="mt-1">
          g) The Brand shall be solely responsible for obtaining and maintaining
          accurate records of consent from all recipients of messages or calls
          facilitated through the Company’s platform. Such consent must
          explicitly include but is not limited to: (I) the specific purpose of
          the communication, and (ii) the duration for which consent is valid.
          The Brand agrees to provide documented proof of such consent to the
          Company upon request, within twenty-four (24) hours of notice. In the
          event of failure to provide such proof within the specified timeframe,
          the Company reserves the right to immediately suspend services, and
          the Brand may be subject to indemnity obligations as set forth in this
          Agreement.
        </div>
      </div>
    ),
  },
  {
    serial_no: "ix",
    value: `Confidentiality: The Brand is solely responsible for maintaining the security and confidentiality of its access credentials, tokens, and other sensitive information. The Brand agrees to immediately notify the Company of any disclosure, unauthorized use, or breach of security with respect to its information. In the event of unauthorized access arising from the Brand's negligence, the Brand shall indemnify and hold harmless the Company against any claims, damages, losses, or liabilities, including legal costs, resulting from such unauthorized access
    `,
  },
  {
    serial_no: "x",
    value: `Intellectual Property Rights:All intellectual property rights associated with the deliverables created by the influencer (the "Deliverables") shall remain exclusively vested with the influencer. The brand is hereby granted a non-exclusive, limited, revocable, and non-transferable license to use the Deliverables solely for the specific purposes and within the duration as agreed mutually between the Parties. The license granted to the brand shall be limited to the period as mutually agreed for which the brand will purchase content rights (hereinafter referred to as the “License”). This license does not convey any ownership rights to the brand nor authorize any usage of the Deliverables beyond the expressly agreed scope, including but not limited to use on social media channels or other platforms. Upon the expiration of the agreed content usage period, the brand's license to use the Deliverables shall automatically terminate without any further action required by the influencer.

    `,
  },
  {
    serial_no: "xi",
    value: `Disclaimer of Warranty: The Platform is provided on an "as is" and "as available" basis, without warranties of any kind, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. The Company does not guarantee that the platform will be secure, available, uninterrupted, error-free, or free of viruses. The Company does not warrant that any content or interactions on the platform will meet users’ expectations or be free from glitches. The Brand acknowledge that the Company is a marketplace and does not guarantee the performance, quality, or behaviour of any users or transactions facilitated through the platform. Use of the platform is at the user’s own risk, and the Company reserves the right to modify or update the platform at any time without prior notice.

    `,
  },
  {
    serial_no: "xii",
    value: `Force Majeure: The Company shall not be liable for any failure or delay in the availability, functionality, or performance of the platform, or any other obligations under this Agreement, due to events beyond its reasonable control ("Force Majeure Event"). Such events include, but are not limited to: (i) acts of God, natural disasters, or extreme weather; (ii) war, terrorism, or armed conflict; (iii) strikes, labour disputes, or shortages; (iv) government actions, laws, or regulations; (v) epidemics, pandemics, or health emergencies; (vi) telecommunications or power failures; (vii) technical malfunctions, cyberattacks, or data breaches; (viii) civil unrest; or (ix) any other event beyond the Company's control. During a Force Majeure Event, the Company shall use commercially reasonable efforts to mitigate disruption. However, the Company shall not be responsible for any failure or delay in providing technical support, including platform availability, performance, or response times, nor for any limitation of support services.
    `,
  },
  {
    serial_no: "xiii",
    value: `Indemnification and Limitation of Liability:Notwithstanding any indemnities provided elsewhere in this Agreement, the Brand agrees to indemnify, defend, and hold harmless the Company, its affiliates, officers, directors, employees, agents, and representatives (collectively, the “Indemnified Parties”) from and against any and all claims, liabilities, damages, losses, costs, expenses (including legal fees), and judgments arising out of or related to the following: (i) the Brand’s materials, content, and intellectual property provided to the influencers shall be owned by the Brand or that the Brand has obtained all necessary rights, licenses, and permissions to use such materials and shall not infringe upon the intellectual property rights of any third party and will be liable for any resulting claims or costs; (ii) to the extent if applicable, the Brand’s products shall comply with all applicable laws, including the provisions of the Legal Metrology Act, 2009, and other regulatory requirements related to product labelling, packaging, and marketing; (iv) the Brand shall not bring any claims against the Company for acts or omissions of the influencer(s), including defamatory statements or any other behaviour by the influencer(s), and the Brand acknowledges and agrees that the Company’s role is solely as a facilitator and is not responsible for intervening in or resolving disputes between the Brand and the influencer(s); and (v) the Brand acknowledges that the Company’s support is limited to assisting at its discretion and does not extend to legal or financial obligations in such matters. The Brand’s indemnification obligations shall survive the termination or expiration of this Agreement. In no event shall Company’s total liability to the Brand exceed INR 10,000.
    `,
  },
  {
    serial_no: "xiv",
    value: `Support Team: In the event of any disputes or unresolved queries between the Brand and the Influencer, the Brand may contact the Company’s support team for assistance. The support team can be reached at support@oneimpression.io, and the Company will make reasonable efforts to facilitate communication and assist in resolving the issue in a timely manner. However, the Company does not assume liability for any disputes or disagreements between the Brand and the Influencer.
    `,
  },
  {
    serial_no: "xv",
    value: (
      <div className="flex flex-col">
        <div>Payment Structure and Product Flow</div>
        <div className="mt-1">
          Brands subscribing to OI Advance Module (Self-Managed Campaign model)
          shall adhere to the following terms and conditions related to
          payments, and campaign execution.
        </div>
        <div className="mt-1">
          i) Subscription Plans: The Company shall offer subscription plans for
          OI Advance campaigns, which may be purchased by Brands through the
          online payment system available on the Platform. These plans will
          include predefined features and benefits as detailed in their
          respective descriptions. The Company reserves the right to extend
          active subscription plans or offer discounts on fixed prices at its
          discretion. Subscription plans purchased by a Brand are
          non-transferable and may only be used by the subscribing Brand for its
          campaigns. Any modifications to the plan will be communicated to the
          Brand through the Platform or via email.
        </div>
        <div className="mt-1">
          ii) Self-Managed Campaigns: Brands subscribing to OI Advance will
          independently manage their influencer marketing campaigns through the
          platform by selecting influencers of their choice who fit within their
          budget. The Company will not be responsible for campaign execution,
          negotiation, or deliverable management under this model.
        </div>
        <div className="mt-1">
          iii) Prepaid Campaign: For campaigns executed under the OI Advance
          model, the Brand has the option to pay 100% of the campaign amount in
          advance through the online payment system available on the Platform.
          This campaign amount is separate from any fees paid for subscribing to
          the Platform. Upon receipt of the campaign payment, the campaign
          delivery will be deemed automatically approved by the Brand, and the
          Influencer shall proceed with executing the campaign deliverables
          accordingly.
        </div>
        <div className="mt-1">
          iv) Transparent Pricing Model: Under the subscription model, the
          Company will not charge margins or markups on influencer costs from
          the Brand. The prices quoted to the Brand for campaign deliverables
          shall reflect the exact amount payable to the influencer, ensuring
          full transparency.
        </div>
        <div className="mt-1">
          v) Automated Campaign Delivery: The Company shall enable automated
          delivery of campaign deliverables for both Brands and influencers
          through the Platform. This process will include real-time updates and
          progress tracking for campaigns. The Platform will facilitate direct
          1:1 communication between Brands and influencer. Brands can send text
          messages, voice notes, and initiate calls with influencer via the
          Platform. Such communication features are intended solely for
          campaign-related discussions. In case of disputes or unresolved
          queries between the Brand and the influencer, either party may contact
          the Company's support team at support@oneimpression.io
        </div>
      </div>
    ),
  },
];

export const BrandTermsAndConditions = () => {
  return (
    <TermsAndConditionsLayout>
      <div className="mx-auto mt-8 lg:mt-16 px-5 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-2xl not-italic text-center font-bold font-serif text-oi-gray-900 tracking-wider mb-5">
          BRAND TERMS AND CONDITIONS
        </div>
        <div className="mb-5 flex justify-center">
          <div className="text-xs flex flex-col w-4/5 not-italic font-normal font-serif text-black mb-2">
            By reading and accepting these Terms and Conditions, Terms of Use
            and Privacy Policy (collectively referred to as the "Agreement"),
            you, the Brand, acknowledge and agree that these policies form an
            integral part of the contractual relationship between you and One
            Impression. By accepting the terms herein, you represent and warrant
            that you have understood and agreed to abide by the provisions set
            forth in this Agreement, and that you are legally bound by its terms
            and conditions. For the purposes of this Agreement, the "OI Advance”
            offers brands influencer marketing packages with tailored features
            and benefits. Brands can connect with influencers, manage campaigns,
            and access this platform to enhance marketing efforts, hereinafter
            referred to as "One Impression," "we," "us," "our," or "the
            Company." You, the Brand, or the Agency managing your account (if
            applicable), are hereinafter referred to as the "User," "Brand,"
            "you," or "your," as applicable.
            {conditions.map(({ serial_no, value }, index) => (
              <div
                className="flex flex-row mt-5"
                key={`${index}_${serial_no}_brandTandC`}
              >
                <div>{serial_no}.</div>
                <div className="ml-5">{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </TermsAndConditionsLayout>
  );
};
