import React from "react";

// COMPONENTS
import { PrivacyPolicyLayout } from "../../layouts/PrivacyPolicy";
import { Link, FontBold, Space } from "../../components/PrivacyPageComponents";

// UTILS
import { OI_DOMAIN_URL, LINK_TYPES } from "../../utils/commonUtils";

const { MAIL, PHONE } = LINK_TYPES;

const purposeOfCollectingOptions = [
  {
    serial: "i)",
    value: "to render Services and facilitate your use of the Platform.",
  },
  {
    serial: "ii)",
    value:
      "to respond to your inquiries or fulfil your requests for information about the various Services offered on the Platform.",
  },
  {
    serial: "iii)",
    value:
      "to provide you with information about Services available on the Platform and to send you information, materials, and offers.",
  },
  {
    serial: "iv)",
    value:
      "to   send   you   important   information   regarding   the   Platform, changes in terms and conditions, user agreements, and policies and/or other administrative information.",
  },
  {
    serial: "v)",
    value: "to send you surveys and marketing communications.",
  },
  {
    serial: "vi)",
    value: "to improve user experience.",
  },
  {
    serial: "vii)",
    value:
      "to help you address your problems with respect to incurred on the Platform including addressing any technical problems.",
  },
  {
    serial: "viii)",
    value:
      "to protect the integrity and for proper administering of the Platform.",
  },
  {
    serial: "ix)",
    value:
      "to conduct academic research, surveys, analytical studies on various aspects including user behavior, user preferences etc.",
  },
  {
    serial: "x)",
    value:
      "to respond to legal, judicial, quasi-judicial process and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law.",
  },
  {
    serial: "xi)",
    value: "to implement information security practices.",
  },
  {
    serial: "xii)",
    value:
      "to determine any security breaches, computer contaminant or computer virus",
  },
  {
    serial: "xiii)",
    value:
      "to investigate, prevent, or take action regarding illegal activities and suspected fraud.",
  },
  {
    serial: "xiv)",
    value:
      "to enable a potential buyer or investor to evaluate the business of the Company.",
  },
  {
    serial: "xv)",
    value:
      "Business or Research Purposes: The Information saved (except Sensitive Personal Information), is used for business or research purposes, including improving and customizing the Platform for ease of use and the products and services offered by us. We may archive this information to use it for future communications, for providing updates and/or surveys",
  },
  {
    serial: "xvi)",
    value:
      "Aggregating Information / Anonymized data: We may aggregate Information and analyze it in a manner to further accentuate the level of services that we offer to our customers. This Information includes average number of Users of the Platform, the average clicks of the services, the features used, the response rate, etc. and other such statistics regarding groups or individuals. In doing so, we shall   not   be   making   disclosures   of   any   Sensitive   Personal Information as defined above",
  },
];

export const PrivacyPolicyPage = () => {
  return (
    <PrivacyPolicyLayout>
      <div className="mx-auto px-5 mt-8 lg:mt-16 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-4xl not-italic font-bold font-sans text-oi-gray-900 tracking-wider mb-5">
          Privacy Policy
        </div>
        <div className="mb-5">
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            ANYCAST TECHNOLOGY PRIVATE LIMITED (referred as “the Company” or
            “we” or “us” or “our") is the owner of the website domain at
            https://oneimpression.io/ and other associated/ancillary
            applications, products, websites, subdomains, services and all other
            variations and sub-domains of the same (hereinafter referred to as
            “Platform”). The provision of the Services through the Platform is
            conditioned upon your acceptance of the terms and conditions
            contained in Terms of Service as available on Platform and this
            privacy policy (“
            <FontBold
              text="Privacy
            Policy"
            />
            ”).
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            THIS PRIVACY POLICY IS AN ELECTRONIC RECORD IN THE FORM OF AN
            ELECTRONIC CONTRACT FORMED UNDER THE INFORMATION TECHNOLOGY ACT,
            2000 AND THE RULES MADE THEREUNDER AND THE PROVISIONS PERTAINING TO
            ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE
            INFORMATION TECHNOLOGY ACT, 2000 OR ANY RELEVANT STATUTE OR
            REGULATION UNDER ANY APPLICABLE JURISDICTION. THIS PRIVACY POLICY
            DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
          </div>

          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            We are committed to keeping your personal data private and secure.
            We process any personal data we collect from you in accordance with
            the applicable laws including but not limited to The Information
            Technology (Reasonable security practices and procedures and
            sensitive personal data and information) Rules, 2011 along with The
            Digital Personal Data Protection Act, 2023 (as and when applicable)
            and any other laws amended and enacted from time to time along with
            the regulations and the provisions of this Privacy Policy. We have
            taken considerable efforts to ensure that we only collect minimal
            data from you and only to the extent necessary in order for us to
            provide you with Services. The purpose of this Privacy Policy is to
            ensure that there is an intact charter to collect, use and protect
            any personal and/or sensitive data collected by us. This Privacy
            Policy defines our procedure for collection, usage, processing,
            disclosure and protection of any information obtained by us through
            the Platform. Capitalized terms that are not defined in this Privacy
            Policy shall have the same meaning as ascribed to them in our Terms
            of Service. Any reference made to Privacy Policy in this document
            shall mean and refer to the latest version of the Privacy Policy.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #CONSENT
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            This Privacy Policy constitutes a legally binding agreement between
            you and the Company. By accessing the Platform or using our
            services, you acknowledge, understand, and consent to this Privacy
            Policy. If you do not agree, please refrain from using the Platform
            or its services.
          </div>

          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            If you withdraw your consent or choose not to provide personal
            information, we may be unable to provide certain services.
            Withdrawal of consent can be sent to support@oneimpression.io. The
            Company is not liable for any loss or damage resulting from data
            disclosure if such disclosure is necessary for legitimate purposes
            or caused by factors beyond the Company’s control.
          </div>

          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            Please be advised that any Information procured by us, shall be:
            <ul className="list-disc ml-4">
              <li>processed fairly and lawfully for rendering the Services.</li>
              <li>obtained only for specified and lawful purposes.</li>
              <li>
                adequate, relevant and not excessive in relation to the purpose
                for which it is required.
              </li>
              <li>
                able to be reviewed by the User, from time to time and updated-
                if need arises.
              </li>
              <li>
                and not kept longer than for the time which it is required or
                the purpose for which it is required or as required by the
                applicable law.
              </li>
            </ul>
          </div>

          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            If you do not agree with this Privacy Policy, you may refuse or
            withdraw your consent at any time or alternatively choose to not
            provide us with any Personal Information, you understand that under
            such circumstances, we may be unable to render Services. Any such
            intimation to withdraw your consent can be sent to
            <Space />
            <Link
              text="support@oneimpression.io"
              href="support@oneimpression.io"
              type={MAIL}
            />
            .
          </div>

          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE SUSTAINED BY REASON OF
            ANY DISCLOSURE (INADVERTENT OR OTHERWISE) OF ANY DATA, IF THE SAME
            IS EITHER (A) REQUIRED FOR SHARING YOUR INFORMATION FOR LEGITIMATE
            PURPOSES; OR WAS CAUSED THROUGH NO FAULT, ACT, OR OMISSION OF THE
            COMPANY.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #TYPES OF INFORMATION COLLECTED BY US
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            <ul className="list-disc ml-4">
              <li>
                <FontBold text="“Personal Data”" /> means and includes any
                Information that relates to a natural person through which an
                individual is identified, such as the name, email id, country of
                origin or any other information relevant to product choice and
                preferences provided by a User, including but not limited to
                information gathered through availing Services.
              </li>
              <li>
                <FontBold text="“Sensitive Personal Data”" />
                means and includes information relating to (i) financial
                information such as bank account or credit card or debit card or
                other payment instrument details of the Influencer.
              </li>
              <li>
                <FontBold text="“Technical Information”" /> means and includes
                any Information gathered through various technologies that may
                employ cookies or similar technologies to automatically record
                certain information from your device through which you use the
                Platform. This technical information may include your Internet
                Protocol (IP) address.
              </li>
              <li>
                <FontBold text="“Non-Personal Information”" />
                means and includes any information that does not reveal your
                specific identity, such as, browser information, information
                collected through Cookies (as defined below), pixel tags and
                other technologies, demographic information, etc. As is true
                with most websites, our Company gathers some information
                automatically when you visit the Platform. When you use the
                Platform, we may collect certain information about your computer
                or mobile to facilitate, evaluate and verify your use of the
                Platform. For example, we may store environmental variables,
                such as browser type, operating system, speed of the central
                processing unit (CPU), referring or exit web pages, click
                patterns. This information is generally collected in aggregate
                form, without identifying any User individually. (The Personal
                Data, Sensitive Personal Data, Technical Information, and
                Non-Personal Information are collectively referred to as
                "Information").
                <div className="mt-3">
                  For the purposes of this section, "Personal Data" and
                  "Sensitive Personal Data" include data that is originally in
                  digital form as well as data in non-digital form that is
                  subsequently digitized.
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #COLLECTION OF INFORMATION
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 ml-4">
            Information may be collected in various ways including during the
            course of your registration as a User on the Platform, or while
            availing certain Services offered on the Platform. We may receive
            Information about you from third-party platform, such as social
            media platforms, marketing and advertising firms, commercially
            available sources and business partners to whom you have consented
            disclosure of such Information as well as publicly available
            information available in the public domain and on the internet.
            (App’s) use and transfer to any other app of information received
            from Google APIs will adhere to Google API Services User Data
            Policy, including the Limited Use requirements.Please do note that
            each category of Information may be treated differently as per this
            Privacy Policy.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #PURPOSE FOR COLLECTING OF INFORMATION
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            <div>
              The Company collects, uses, stores and processes your Information
              for any purpose as may be permissible under applicable laws
              (including where the applicable law provides for such collection,
              usage, storage or processes in accordance with the consent of the
              User) and shall include the following:
              <div className="ml-4">
                {purposeOfCollectingOptions.map(({ serial, value }, index) => (
                  <div
                    className="flex w-4/5 flex-row"
                    key={`${index}_${serial}_privacyPolicy`}
                  >
                    <div className="w-4">{serial}</div>
                    <div className="ml-4">{value}</div>
                  </div>
                ))}
              </div>
              <div>
                [Individually and collectively referred to as ("Legitimate
                Purposes")]
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #SHARING AND DISCLOSURE OF YOUR INFORMATION
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            <div className="flex flex-col">
              <div>
                We do not rent, sell or disclose or share any Information that
                we collect from you, with third parties, save and except in
                order to provide you with the Services. Any such disclosure, if
                made, shall be in accordance with this Privacy Policy and as per
                the procedure prescribed by law and in compliance with our legal
                obligations. We may share your Information in circumstances and
                for the purposes as specified hereunder:
              </div>
              <div>
                We shall share the information with the third-party service
                providers/ vendors, to provide you with the Services.{" "}
              </div>
              <div>
                We may disclose any Information provided by you on the Platform
                as may be deemed to be necessary or appropriate: for achieving
                the Legitimate Purposes set out above; under applicable law,
                including laws outside your country of residence.{" "}
              </div>
              <div> - to comply with legal process.</div>
              <div>
                {" "}
                - to respond to requests from public and government authorities
                outside your country of residence.{" "}
              </div>
              <div>
                {" "}
                - to protect our operations or those of any of our affiliates.
              </div>
              <div>
                {" "}
                - to protect our rights, privacy, safety or property, and/that
                of our affiliates, you or others.{" "}
              </div>
              <div>
                {" "}
                - to allow us to pursue available remedies or limit the damages
                that we may sustain.{" "}
              </div>
              <div> - to protect against legal liability.</div>
              <div>
                {" "}
                - to protect the personal safety of Users of the Platform; or{" "}
              </div>
              <div>
                {" "}
                - to prevent or investigate possible wrongdoing in connection
                with the Platform.
              </div>
              <div>
                {" "}
                Merger or Acquisition: We may share Information upon merger or
                acquisition of Company with another company. We shall transmit
                and transfer the Information upon acquisition or merger of
                Company with another company.
              </div>
              <div>
                {" "}
                With our service providers: We may share Information with other
                service providers on a need-to-know basis, subject to
                obligations of confidentiality for provision of Services. We
                hereby clarify that we work with institutions, vendors,
                partners, advertisers, and other service providers, including
                (but not limited) to those who provide products or services such
                as contact Information verification, website hosting, data
                analysis, providing infrastructure, information technology
                services, auditing services and other similar services, in
                different industries and categories of business by virtue of
                lawful contracts instituted between such third parties and
                Company to improve our product and services. Accordingly, we may
                share your Information with such service provider in order to
                provide you with Services.
              </div>
              <div>
                {" "}
                Employees /Agents of Company: We follow a strict confidentiality
                policy with regard to disclosure of confidential information to
                our employees or other personnel. There may be situations where
                we may disclose the confidential information only to those of
                our employees and other personnel on a need-to-know basis. Any
                breach of confidential information by the employees and
                personnel within the Company is dealt with stringently by us.
              </div>
              <div>
                {" "}
                Except for the Information disclosed pursuant to this section,
                the Company may share Information only if you authorize us to do
                so.
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #LINK TO OTHER WEBSITES AND THIRD-PARTY SERVICES
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            <div className="flex flex-col">
              <div>
                Our Platform may provide links to other sites. These links are
                provided for your convenience only and the provision of these
                links does not mean that sites are related or associated with
                us. Please note that these sites have their own terms of use and
                privacy policy. You should check their privacy policy before you
                submit Your Personal Information or any other data with them. We
                don’t guarantee the content and the security of those sites.{" "}
              </div>
              <div>
                {" "}
                We may have certain features on our Platform which may be hosted
                by third parties, your interaction with such features shall be
                governed by the privacy policy of such third parties.
              </div>
              <div>
                {" "}
                We shall not be responsible for any loss, damage, claim or
                expense caused as a result of you accessing these third-party
                sites and features.{" "}
              </div>
              <div>
                {" "}
                We may use your Information to send you promotional Information
                about third parties which, we think you may find interesting, if
                you tell us that you wish this to happen. We shall not be
                responsible for any disclosure of Information due to
                unauthorized third-party access or other acts of third parties
                or acts or omissions beyond our reasonable control and you agree
                that you will not hold us responsible for any breach of security
                unless such breach has been caused as a direct result of our
                negligence or willful default.{" "}
              </div>
              <div>
                {" "}
                We use the support services of third-party platforms and/or
                companies to direct you to payment gateways when you opt to pay
                for our Services. Your financial information is collected,
                stored and retained by such third-party platforms. We and such
                designated third-party platforms undertake measures designed to
                provide a security level that is appropriate to the risks of
                processing your personal information. However, you are requested
                to check and consent to the “Privacy Policy” of such third-party
                platforms in order to accept how such third-party platforms
                handle your Information.
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #CHILDREN’S PRIVACY
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 flex flex-col">
            <div>
              Children under the age of 18 may register as Influencers on the
              Platform or may be requested by Brands to participate in a
              Campaign. Any such registration, use of the Platform, or
              participation in a Campaign by a child will require verifiable
              consent from the child’s parent or legal guardian.
            </div>
            <div>
              {" "}
              If you are a parent or legal guardian, and you or your child wish
              to associate with the Company, please read the following section
              carefully:{" "}
            </div>
            <div>
              {" "}
              For your child to have their own Account or to be onboarded for a
              Brand’s Campaign, we may need your permission to collect, use, or
              disclose your child’s information as described in this Privacy
              Policy. By allowing your child to use our Services, you and your
              child entrust us with your Personal Data. We understand this is a
              significant responsibility and are committed to protecting your
              information and giving you control.{" "}
            </div>
            <div>
              Once you grant permission for your child to have an Account,
              create a profile, or be included in a Campaign, their Account will
              generally be treated like your own with respect to the information
              we collect. All rights and obligations stated in this Privacy
              Policy, including those regarding consent, collection, and
              disclosure, will apply to both you and your child.{" "}
            </div>
            <div>
              At any time, parents can refuse to permit us to collect further
              Personal Data from their children associated with a particular
              Account or Campaign. Parents may also request that we delete any
              Personal Data collected in connection with that Account or
              Campaign. Please note that a request for deletion may result in
              the termination of the Account, Campaign, subscription, or other
              related services.
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5 uppercase">
            #About Verifiable Parental Consent:
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 flex flex-col">
            <div>
              Email Consent. If the Company wishes to collect Personal Data from
              a child during Account creation or at the time of Campaign
              confirmation (for Influencers without an Account), we will first
              seek a parent or legal guardian’s consent through email
              verification. In this email, we will explain:{" "}
            </div>
            <div>- What information we are collecting, </div>
            <div>- How we plan to use it,</div>
            <div> - How you can provide consent, and </div>
            <div>- How you can revoke your consent.</div>
            <div>
              {" "}
              If parental consent is not provided by clicking the link shared in
              the email, we will delete the parent’s contact information, and
              any information collected from the child in connection with the
              activity.
            </div>
            Whats wrong here
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5 uppercase">
            #KYC (Know Your Customer)
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 flex flex-col">
            <div>
              <div>
                If your child is participating in a Campaign that involves a
                monetary transaction, we are legally required to verify your KYC
                before processing any monetary incentives. In such cases, KYC
                verification will be conducted through our third-party service
                provider. If you do not consent to the KYC process, you may
                reject the KYC request or contact us at
                support@oneimpression.io.
              </div>
              <div>
                {" "}
                Please note that, to protect children’s privacy and security, we
                will take reasonable steps to verify a parent or legal
                guardian’s identity before granting access to any Personal Data.{" "}
              </div>
              <div>
                If you are a parent or guardian and believe that your child has
                provided us with Personal Data without your consent, please
                contact us at support@oneimpression.io. We will dispose of the
                data in compliance with applicable laws and regulations.
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #DATA PROTECTION RIGHTS
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 flex flex-col">
            <div>
              You have certain rights when it comes to your Personal
              Information. Subject to any exemptions provided by the applicable
              laws, you have the following rights:{" "}
            </div>
            <div>
              {" "}
              Rectifying, correcting, updating and removing Your information:
              You can access, edit, modify and/or update your Personal
              Information by logging into your user profile or you can write to
              us via email in case you wish to exercise this right.
            </div>
            <div className="font-bold">
              Accessing and updating or deleting Your information:
            </div>
            <div>
              Our Services and related documentation on our Platform provide you
              with the ability to access, update and delete certain Personal
              Information from your Account, if provided. We will provide you
              with information about whether we hold any of your Personal
              Information on request. We will respond to such requests within a
              reasonable timeframe. Please note that we may need to retain
              certain information for recordkeeping purposes, to complete our
              Services and related obligations to you or to comply with our
              legal obligations.
            </div>
            <div className="font-bold">
              Object or restrict processing of Your information
            </div>
            <div>
              You have the right to: (i) object to our processing of your
              Personal Information; and/or (ii) request that we restrict the
              processing of your Personal Information.
            </div>
            <div className="font-bold">Portability</div>
            <div>
              You shall have the right to request us to transfer Your Personal
              Information to another controller, or directly to you, in a
              structured, commonly used and machine-readable format.
            </div>
            <div className="font-bold">Summary</div>
            <div>
              You shall have the right to obtain summary of your data and the
              processing activities undertaken with respect to the data being
              processed.
            </div>
            <div className="font-bold">Identities</div>
            <div>
              You shall have the right to know the identities of the Data
              Processors with whom the personal data has been shared by us,
              along with a description of the personal data so shared.
            </div>
            <div className="font-bold">Other Information</div>
            <div>
              You shall have the right to know any other such information with
              respect yo your personal data and its processing, as prescribed by
              the applicable law and this Privacy Policy.
            </div>
            <div className="font-bold">Assign Nominee</div>
            <div>
              You shall have the right to assign such nominee with respect to
              your Personal Data, who may exercise these rights in the event of
              your death or incapacity which shall mean inability to exercise
              such rights including but is not limited to unsoundness of mind or
              infirmity of the body and any other incapacity as prescribed by
              the applicable law.
            </div>
            <div className="font-bold">Portability</div>
            <div>
              You shall have the right to request us to transfer Your Personal
              Information to another controller, or directly to you, in a
              structured, commonly used and machine-readable format.
            </div>
            <div>
              In order to exercise these rights, you may write to the Data
              Protection officer at @oneimpression.io or please contact us on
              +91 9311124148.
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #STORAGE AND TRANSFER OF INFORMATION
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 flex flex-col">
            <div>
              Your Information will primarily be stored in electronic form;
              however, certain data may also be stored in physical form. We
              primarily store all Information within the territory of India. If
              your Information is transferred outside the jurisdiction of India,
              we will notify you of such a transfer.
            </div>
            <div>
              We may enter into agreements with third parties (within or outside
              India) to store or process your information. These third parties
              may implement their own security standards to protect your
              information. On a commercially reasonable basis, we will require
              such third parties to adopt adequate security standards to
              safeguard your information
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #COOKIES AND OTHER TRACKING TECHNOLOGIES
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            Our Platform may use “cookies” and other technical tools to track
            your usage. “Cookies” are small text files consisting of
            alphanumeric characters used to collect information about Platform
            activity. Cookies do not grant us access to your computer or mobile
            device. We use session cookies solely to maintain session
            information for authentication purposes.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #DATA RETENTION
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            We will retain your Information for as long as your Account is
            active or as needed to provide our Services or as required to
            maintain archival records of transactions. We shall retain and use
            the Information collected by us as necessary to comply with our
            legal obligations, resolve disputes or for other Legitimate Purposes
            as prescribed by the law. If you cancel/ deactivate/ unsubscribe
            your account with us, we are not under any obligation to retain your
            Information. However, we may retain the Information pertaining to
            the User for the maximum period permitted under the law of the land
            from the date of deactivation of User’s Account.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #SECURITY MEASURES AND SAFEGUARDS
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            You agree and accept that your Information may be stored in third-
            party cloud service infrastructure providers. While all reasonable
            attempts have been taken from our end to ensure the safe and secure
            storage of your data with reasonable security safeguards, we shall
            not be liable for any data breach on the part of the third-party
            cloud service infrastructure provider that was beyond our control.
            In addition to the security measures put in place by the third-party
            cloud service infrastructure provider for safe and secure storage of
            your Information, we use certain physical, managerial, technical or
            operational safeguards as per industry standards and established
            best practices to protect the Information we collect. We use
            reasonable security practices and procedures and use secure servers
            as mandated under applicable laws for the protection of your
            Information. We review our Information collection, storage, and
            processing practices, including physical security measures to guard
            against unauthorized access to systems. However, as effective as
            these measures are, no security system is impenetrable. We cannot
            guarantee the security of our database, nor can we guarantee that
            the Information you supply will not be intercepted while being
            transmitted to us over the internet. However, since the internet is
            not a 100% secure environment, we on best effort basis ensure the
            security of any information the User transmits to us and that the
            information may not be accessed, disclosed, altered, or destroyed by
            breach of any of our physical, technical, or managerial safeguards.
            Please note that e-mails and other communications the User sends to
            us through our Platform are not encrypted, and we strongly advise
            the User not to communicate any confidential information through
            these means.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #UPDATES TO THIS PRIVACY POLICY
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            We may change the data privacy practices and update this Privacy
            Policy as and when the need arises, and the same will be made
            available on the Platform. But our commitment to protect your
            privacy will continue to remain. We suggest that you regularly check
            this Privacy Policy to apprise yourself of any updates. Your
            continued use of Platform and Services or provision of Information
            thereafter will imply your unconditional acceptance of such updates
            to this Privacy Policy. Further, we retain the right at any time to
            deny or suspend access to all, or any part of, the service to anyone
            who we reasonably believe has violated any provision of this Privacy
            Policy
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #LIABILITY
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2 flex flex-col">
            <div>
              We expressly disclaim all the liabilities, whether legal,
              indirect, consequential or special or otherwise, arising from any
              act, omission or misconduct of the visitors of this website or any
              other liability arising from such actions. You hereby acknowledge
              that we function solely as a ‘facilitator’ between the Brand and
              the Influencer and are therefore indemnified against any
              liabilities arising out of these actions.
            </div>
            <div>
              - By visiting this site, you also acknowledge and implicitly
              accept this disclaimer.
            </div>
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #MISCELLANEOUS
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            ENFORCEABILITY OF THE REMAINDER OF THIS PRIVACY POLICY. THIS PRIVACY
            POLICY DOES NOT APPLY TO ANY INFORMATION OTHER THAN THE INFORMATION
            COLLECTED BY US THROUGH THE PLATFORM. THIS PRIVACY POLICY SHALL BE
            INAPPLICABLE TO ANY UNSOLICITED INFORMATION YOU PROVIDE US THROUGH
            THE PLATFORM OR THROUGH ANY OTHER MEANS. ALL UNSOLICITED INFORMATION
            SHALL BE DEEMED TO BE NON-CONFIDENTIAL AND WE SHALL BE FREE TO USE
            AND/ OR DISCLOSE SUCH UNSOLICITED INFORMATION WITHOUT ANY
            LIMITATIONS. THE RIGHTS AND REMEDIES AVAILABLE UNDER THIS POLICY MAY
            BE EXERCISED AS OFTEN AS NECESSARY AND ARE CUMULATIVE AND NOT
            EXCLUSIVE OF RIGHTS OR REMEDIES PROVIDED BY LAW. RIGHTS UNDER THIS
            POLICY MAY BE WAIVED ONLY IN WRITING. DELAY IN EXERCISING OR
            NON-EXERCISE OF ANY SUCH RIGHT OR REMEDY DOES NOT CONSTITUTE A
            WAIVER OF THAT RIGHT OR REMEDY, OR ANY OTHER RIGHT OR REMEDY.
          </div>
        </div>

        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #GRIEVANCE OFFICER/ DATA PROTECTION OFFICER
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            In furtherance of the Information Technology Act, 2000 (“IT Act”)
            and the Information Technology (Intermediary Guidelines and Digital
            Media Ethics Code) Rules, 2021 (“Intermediary Guidelines”) along
            with The Digital Personal Data Protection Act, 2023 and the
            prescribed rules therein (as and when applicable), a grievance
            officer/ data protection officer is appointed to ensure compliance
            with the applicable law and the Intermediary guidelines.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            Name: Jivesh Gupta
            <br /> Address: Wework DLF two horizon centre, 5th floor, DLF phase
            5 sector 43, golf course road, Gurugram, Haryana
            <br /> Phone:
            <Space />
            <Link href="+91 9311124148" text="+91 9311124148" type={PHONE} />
            <br /> Email:
            <Space />
            <Link
              href="grievances@oneimpression.io"
              text="grievances@oneimpression.io"
              type={MAIL}
            />
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-4">
            The officer shall revert to every complaint within 24 hours of
            receipt of the complaint. Further, the Company shall make the best
            possible efforts to redress the complaint within 15 days of receipt
            of the complaint. Any suggestions by the Company regarding use of
            the Services shall not be construed as a warranty.
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            Please feel free to reach out to us by e-mail at
            <Space />
            <Link
              href="grievances@oneimpression.io"
              text="grievances@oneimpression.io"
              type={MAIL}
            />
            <Space />
            in case of any concerns, grievances, or questions relating to our
            privacy or data related practices
          </div>
        </div>
        <div className="mb-5">
          <div className="text-2xl not-italic font-bold font-sans mb-2.5">
            #GOVERNING LAW, DISPUTE RESOLUTION AND JURISDICTION
          </div>
          <div className="text-base not-italic font-normal font-sans text-black mb-2">
            This Privacy Policy is governed by the laws of India. Any action,
            suit, or other legal proceeding, which is commenced to resolve any
            matter arising under or relating to this policy, shall be subject to
            the jurisdiction of the courts at Gurugram, Haryana, India.
          </div>
        </div>
      </div>
    </PrivacyPolicyLayout>
  );
};
