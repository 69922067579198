import React from "react";

// COMPONENTS
import { TermsAndConditionsLayout } from "../../../layouts/TermsAndConditions";
import { FontBold } from "../../../components/PrivacyPageComponents";

const postingTerms = [
  {
    serial_no: "i",
    value:
      "The Influencer is solely responsible for the publication of unapproved content by the Brand. One Impression and its affiliates bear no responsibility for any consequences arising from the publication of such content, and the Influencer agrees to indemnify One Impression against any third-party claims, damages, or losses arising therefrom.",
  },
  {
    serial_no: "ii",
    value:
      "After a Campaign Deliverable is published, the Influencer shall not publish any additional content for a period of 72 hours if such publication would reasonably affect the visibility or prominence of the Campaign Deliverable as per the Brand’s specifications.",
  },
  {
    serial_no: "iii",
    value:
      "If any legal or compliance issue, or a material breach of agreed campaign guidelines, arises post-publication of the Campaign Deliverable, the Brand shall have the right to request its removal. The Influencer agrees to comply with such a request within 24 hours upon receipt of written notification.",
  },
];

const campaignDeliverableTerms = [
  {
    serial_no: "a",
    value: "complies with all applicable laws and regulations.",
  },
  {
    serial_no: "b",
    value:
      "complies with all terms and conditions set by the platform on which the Campaign Deliverable is published by the Influencer.",
  },
  {
    serial_no: "c",
    value:
      "The Influencer agrees to abide by all guidelines for all blog posts, social media statuses, tweets, and/or comments, which should be in good taste and free of inappropriate language and/or content promoting bigotry, racism or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age and shall not act in a manner which can harm the Brand’s image and reputation.",
  },
  {
    serial_no: "d",
    value:
      "does not contain any content which you know or suspect (or ought reasonably to have known or suspected) to be false, misleading or deceptive.",
  },
  {
    serial_no: "e",
    value:
      "does not contain any content that is indecent, discriminatory, or offensive or contains or promotes aggressive or illegal behaviour or sexually suggestive imagery. Does not violate the privacy rights, contract rights, intellectual property rights or other rights of any person, corporation or entity.",
  },
];

const codeOfConduct = [
  {
    serial_no: "a",
    value:
      "Always disclose your partnership with the brand clearly and conspicuously in every post or content piece related to the campaign. Use hashtags like #ad, #sponsored, #partner, or similar to make it evident.",
  },
  {
    serial_no: "b",
    value:
      "Ensure that all information provided in your content is accurate and factually correct.",
  },
  {
    serial_no: "c",
    value:
      "Avoid making false claims, exaggerations, or misleading statements about the brand's products or services.",
  },
  {
    serial_no: "d",
    value:
      "Maintain professional and respectful communication with the Brand representatives throughout the collaboration.",
  },
  {
    serial_no: "e",
    value:
      "Respond promptly to emails, messages, and inquiries from the Brand.",
  },
  {
    serial_no: "f",
    value:
      "Create high-quality and engaging content that aligns with your personal style while also meeting the brand's guidelines.",
  },
  {
    serial_no: "g",
    value:
      "Adhere to copyright laws and give proper credit if using third-party content.",
  },
  {
    serial_no: "h",
    value:
      "Not misuse any Campaign Deliverables created by the Influencer post completion of the Campaign.",
  },
  {
    serial_no: "i",
    value:
      "Adhere to the agreed-upon deadlines for content creation and submission.",
  },
  {
    serial_no: "j",
    value:
      "Respect the privacy of your audience and avoid sharing sensitive personal information without consent.",
  },
  {
    serial_no: "k",
    value:
      "Comply with data protection laws and guidelines if and when collecting and using user data for Campaigns.",
  },
  {
    serial_no: "l",
    value:
      "Follow the Brand's Campaign guidelines, including visual aesthetics, tone of voice, and any specific messaging they require.",
  },
  {
    serial_no: "m",
    value: "Seek clarification from the brand if any guidelines are unclear.",
  },
  {
    serial_no: "n",
    value:
      "Avoid engaging in any activity that might damage your credibility or that of the brand.",
  },
  {
    serial_no: "o",
    value:
      "Refrain from using dishonest practices such as buying followers, likes, or engagement.",
  },
  {
    serial_no: "p",
    value:
      "Consider building long-term relationships with brands based on mutual trust and successful collaborations.",
  },
  {
    serial_no: "q",
    value:
      "Demonstrate reliability and professionalism to enhance your reputation as a trustworthy influencer.",
  },
];

const additionalTCforOneClub = [
  {
    serial_no: "a",
    value: (
      <>
        <div>
          About OneClub: Through OneClub we are building an ecosystem for the
          Influencers leveraging technology and tools to provide them end to end
          solutions in their overall content creation journey, where we would be
          exclusively managing them and their business. OneClub services offer
          the following additional services to the Influencer:
        </div>
        <ul className="list-disc ml-4">
          <li>
            From comprehensive CRM to dedicated talent manager to data-driven
            insights and financial management, we are providing the creators
            with the resources to make informed decisions and streamline every
            aspect of their journey.
          </li>
          <li>
            Various growth tools such as partnerships across various verticals,
            access to Elite exclusive communities, etc.
          </li>
          <li>
            Access to a diverse range of income streams, including brand
            partnerships, sponsorships, affiliate marketing, online and offline
            events, and digital product sales.
          </li>
        </ul>
      </>
    ),
  },
  {
    serial_no: "b",
    value:
      "The Influencer has represented and assured to the Company that he/ she has the requisite competence, expertise and talent and has approached the Company to perform, be associated and be promoted exclusively by the Company, as an Artist for influencer marketing, managing brands for the Artist, end to end, modelling in advertisements and/or commercials, video album, personal appearances, endorsement of products, or any other assignment related to the TV, internet, telecommunication networks or any electronic, print media (including but not limited to social media and other similar media whether now known or hereafter introduced or invented or developed) produced by the Company under its own banner and/or by other third parties.",
  },
  {
    serial_no: "c",
    value:
      "In consideration of the exclusivity of the association being offered by the Influencer to the Company and the representations by the Influencer that he/ she has the requisite competence, expertise and talent, the Company has agreed to handle the complete role of artist management and promotion of the Influencer in the manner and on terms and conditions hereinafter mentioned.",
  },
  {
    serial_no: "d",
    value:
      "The OneClub feature may be availed by paying a separate fee as per the pricing plans set out on the Platform or as otherwise agreed between the Company and the Influencer.",
  },
  {
    serial_no: "e",
    value: (
      <>
        <div>
          The Influencer has approached the Company to perform, be associated
          and promoted exclusively by the Company, and to render his/her
          services for the Assignments procured by the Company and produced by
          the Company under its own banner and/or by other third parties and the
          Company has agreed to handle the complete role of artist management on
          terms and conditions set out herein. It is expressly provided that
          during the Term all assignments, projects undertaken by the Influencer
          shall exclusively be negotiated and handled by the Company. Any
          Assignment procured by the Company will be disclosed to the Company.
        </div>
        <div className="mt-2">
          For the purpose of these Terms, "Assignment" shall mean the work
          assignments and deals procured by the Company or by the Influencer for
          the Influencer to render their services.
        </div>
      </>
    ),
  },
  {
    serial_no: "f",
    value:
      "The Company shall have the exclusive right to promote the Influencer and procure Assignments and handle the complete role of artist management and promotion for the Influencer.",
  },
  {
    serial_no: "g",
    value:
      "The Company shall have the right to receive the revenues on behalf of the Influencer for the Assignments procured by the Company and also for Assignments procured by the Influencer and shall make the payments to the Influencer as mutually agreed.",
  },
  {
    serial_no: "h",
    value:
      "The Company shall have the right to use the name of the Influencer or any name by which the Influencer has acquired, public repute and goodwill, if any, the photographs or other likeness of the Influencer, to write and to publish and permit others to write and publish articles concerning the Influencer for advertising and publicity matter concerned with the Assignment(s) and the Influencer shall not object to any such exploitation.",
  },
  {
    serial_no: "i",
    value:
      "The Influencer hereby agrees and undertakes that he/ she shall take on Assignments only on the terms and conditions consented and agreed upon by the Company and agrees to complete all the assignments as per the contracts of each Assignment. The Influencer agrees and confirms to accept as many Assignments as the Company wishes him/ her to do.",
  },
  {
    serial_no: "j",
    value:
      "The Influencer agrees and undertakes that he/ she shall not accept any Assignments during the Term of this Agreement without the prior written consent and approval of the Company and shall accept such assignments on the remunerations as approved by the Company. Further, such Assignments shall be handled only by the Company and the Company shall be entitled to its share of revenue in respect of the same.",
  },
  {
    serial_no: "k",
    value:
      "The Influencer agrees and undertakes that he/ she shall devote his/ her entire time for all the Assignments and shall attend all the meetings, discussions, shootings, re-shootings, dubbing, rehearsals, photo sessions, or any other sessions concerning the Assignments or any parts thereof as and when required by the Company and as per the schedules decided by the Company. The Influencer agrees and undertakes that he/ she shall make himself/ herself available for publicity which entails press conferences and/ or releases, television and/ or any tour as and when required by the Company.",
  },
  {
    serial_no: "l",
    value:
      "The Influencer agrees that it will not make any public statements with respect to any Assignments without the prior approval of the Company.",
  },
  {
    serial_no: "m",
    value:
      "The Influencer has not and shall not, directly or indirectly, whether by himself/ herself or jointly with another or through any of the Influencer’s agents, enter(ed) into any agreement or arrangements which may in the Company's opinion prejudice and/or conflict with the rights of the Company under these Terms and Conditions.",
  },
  {
    serial_no: "n",
    value:
      "If commercially agreed, the ownership of any Campaign Deliverables created by the Influencer will be passed on to the Brand under whose Campaign such Campaign Deliverables are created.",
  },
  {
    serial_no: "o",
    value:
      "The Influencer shall promptly provide and sign any other documents as may be considered necessary by the Company to effectively carry out provision of these Terms and Conditions.",
  },
  {
    serial_no: "p",
    value:
      "The Company shall not be responsible in any way for any of the Influencer’s unfulfilled obligations and/or liabilities or any of its associates, agents, affiliates etc towards any person, party, company, organisation in connection with the Assignments including finance, employment of other contractual and non-contractual agreements/arrangements of whatsoever nature.",
  },
  {
    serial_no: "q",
    value:
      "The Influencer will complete all of its Assignment(s) undertaken by it even after expiry of the engagement between the Company and the Influencer without claiming any additional revenue from the Company.",
  },
  {
    serial_no: "r",
    value:
      "The Influencer shall indemnify and keep indemnified the Company and hold the Company and its officers, and directors and its parent, subsidiaries and affiliates, harmless from any and all losses, claims, damages, liabilities, reasonable costs and expenses, including reasonable counsel fees, incurred by the Company, arising out of misrepresentation, fraud, gross negligence, or wilful misconduct of, or from the breach of any provision of these Terms and Conditions or any Assignment by the Influencer, of matters relating to either party and the transactions contemplated by them. The Company shall promptly notify the Influencer of any claim or litigation to which the indemnity set forth herein applies.",
  },
  {
    serial_no: "s",
    value:
      "The Influencer agrees and understands that the engagement for talent management under these Terms and Conditions is an exclusive arrangement and the Influencer shall not simultaneously engage any other third-parties entity to undertake same or similar services for the Influencer during the subsistence of the Company’s engagement under these Terms and Conditions.",
  },
  {
    serial_no: "t",
    value:
      "In the event of violation of the exclusivity restriction under these Terms and Conditions and/or if the Influencer commits to any assignment that does not involve the Company, the Influencer will pay the Company a penalty equivalent to 10 times the value of such external assignment as a compensation for lost revenue. The Influencer agrees and understands that the liquidated damages contemplated hereunder are legitimate and fair.",
  },
];

export const InfluencerTermsAndCondition = () => {
  return (
    <TermsAndConditionsLayout>
      <div className="mx-auto mt-8 lg:mt-16 px-5 max-w-lg sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-xl">
        <div className="text-2xl not-italic text-center font-bold font-serif text-oi-gray-900 tracking-wider mb-5">
          INFLUENCER TERMS AND CONDITIONS
        </div>
        <div className="mb-5 flex justify-center">
          <div className="text-xs flex flex-col w-4/5 not-italic font-normal font-serif text-black mb-2">
            <div>
              By reading and accepting these Terms and Conditions, Terms of Use,
              and Privacy Policy (collectively referred to as the
              &quot;Agreement&quot;), you, the Influencer, acknowledge and agree
              that these policies form an integral part of the contractual
              relationship between you and One Impression. By accepting the
              terms herein, you represent and warrant that you have understood
              and agreed to abide by the provisions set forth in this Agreement,
              and that you are legally bound by the terms and conditions
              outlined. For the purposes of this Agreement, &quot;OI
              Advance&quot; is a platform that connects influencers to manage
              collaborations and engage with Brands. The platform enables
              influencers to collaborate with brands, manage campaigns, and
              expand their reach. One Impression, hereinafter referred to as
              &quot;we,&quot; &quot;us,&quot; &quot;our,&quot; or &quot;the
              Company,&quot;. You, the influencer, are hereinafter referred to
              as &quot;Creator&quot; or &quot;Influencer,&quot; or
              &quot;you&quot; or &quot;your,&quot; as applicable.
            </div>
            <div className="mt-5">
              <div>
                A. <FontBold text="Posting Terms:" />
              </div>
              <div className="flex flex-row">
                <div className="ml-5">
                  {postingTerms.map(({ serial_no, value }, index) => (
                    <div
                      className="flex flex-row mt-5"
                      key={`${index}_${serial_no}_postingTerms`}
                    >
                      <div>{serial_no}.</div>
                      <div className="ml-5">{value}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div>
                B. <FontBold text="Preservation of Goodwill" />
              </div>
              <div>
                Influencers are required to produce content in strict adherence
                to the brief provided by the user/brand to avoid unnecessary
                revisions. Influencers shall collaborate in good faith with the
                brand to uphold and safeguard One Impression’s goodwill. Any
                failure to comply may result in liability for damages arising
                from reputational harm or non-conformance with the brief. If the
                Influencer fails to deliver the agreed-upon content within the
                stipulated timeline after accepting the campaign, they will be
                banned from the Platform for thirty (30) days.
              </div>
            </div>
            <div className="mt-5">
              <div>
                C. <FontBold text="Campaign Subject Matter" />
              </div>
              <div>
                i. In case Influencer does not create the Campaign post
                acceptance/receipt of Campaign Subject Matter then under such
                scenario such Influencer’s Account shall be removed from the
                Company’s network and the Influencer shall at their own expense
                return to the Brand the Campaign Subject Matter like items,
                props or Products in the same condition that it was delivered to
                the Influencer. In the event that a Campaign Subject Matter is
                to be returned to the Brand post the Campaign and the Influencer
                does not return the Campaign Subject Matter within the mutually
                agreed time period, the Company and the Brand may hold the
                Influencer liable for any damages that may arise from not
                returning the Campaign Subject Matter and they are entitled to
                all remedies available under law including taking civil and
                criminal actions against the Influencer.
              </div>
              <div>
                ii. Influencers are responsible for providing an accurate
                shipping address and coordinating with the logistics partner,
                including being available to receive delivery-related calls.
                Failure to do so may result in missed deliveries, which could
                prevent the creator from submitting content within the required
                timelines given heading of these clauses.
              </div>
            </div>
            <div className="mt-5">
              <div>
                D. <FontBold text="Compliance with Laws and Regulations" />
              </div>
              <div>
                i. Campaign Deliverables shall comply with all applicable laws,
                regulations, rules, and industry standards, including but not
                limited to those pertaining to advertising, intellectual
                property, consumer protection, and data privacy.
              </div>
              <div>
                ii. The Influencer acknowledges and agrees that all Campaign
                Deliverables shall adhere to the terms, conditions, policies,
                and guidelines of One Impression. By agreeing to participate in
                the campaign, the Influencer confirms that they have reviewed,
                understood, and accepted these terms and policies. One
                Impression shall not be held liable for any claims, disputes, or
                issues arising from scenarios explicitly addressed and governed
                by these terms and conditions.
              </div>
              <div>
                iii. The Influencer agrees to abide by all applicable guidelines
                when creating and publishing blog posts, social media updates,
                tweets, comments, or any other content. Such content must be
                respectful, in good taste, and free from inappropriate language
                or content that promotes bigotry, racism, or discrimination
                based on race, gender, religion, nationality, disability, sexual
                orientation, or age. The Influencer shall also refrain from any
                actions or behavior that may harm or negatively impact One
                Impression’s image, reputation, or goodwill.
              </div>
              <div>
                iv. The content must not contain any material that the
                Influencer knows, suspects, or reasonably ought to know or
                suspect to be false, misleading, or deceptive. It must also be
                free of any indecent, discriminatory, or offensive material and
                must not promote aggressive or illegal behavior, sexually
                suggestive imagery, or violate the rights of any individual,
                corporation, or entity, including privacy rights, contract
                rights, or intellectual property rights.
              </div>
              <div>
                v. The Influencer agrees that, during the course of their
                engagement and at all times thereafter, they shall not, directly
                or indirectly, make any critical, disparaging, or negative
                statements regarding the Company, the Brand, its affiliates,
                campaigns, campaign subject matter, employees, or clients to any
                third parties, including but not limited to print or broadcast
                media.
              </div>
            </div>
            <div className="mt-5">
              <div>
                D. <FontBold text="Compliance with Laws and Regulations" />
              </div>
              <div>
                i. Campaign Deliverables shall comply with all applicable laws,
                regulations, rules, and industry standards, including but not
                limited to those pertaining to advertising, intellectual
                property, consumer protection, and data privacy.
              </div>
              <div>
                ii. The Influencer acknowledges and agrees that all Campaign
                Deliverables shall adhere to the terms, conditions, policies,
                and guidelines of One Impression. By agreeing to participate in
                the campaign, the Influencer confirms that they have reviewed,
                understood, and accepted these terms and policies. One
                Impression shall not be held liable for any claims, disputes, or
                issues arising from scenarios explicitly addressed and governed
                by these terms and conditions.
              </div>
              <div>
                iii. The Influencer agrees to abide by all applicable guidelines
                when creating and publishing blog posts, social media updates,
                tweets, comments, or any other content. Such content must be
                respectful, in good taste, and free from inappropriate language
                or content that promotes bigotry, racism, or discrimination
                based on race, gender, religion, nationality, disability, sexual
                orientation, or age. The Influencer shall also refrain from any
                actions or behavior that may harm or negatively impact One
                Impression’s image, reputation, or goodwill.
              </div>
              <div>
                iv. The content must not contain any material that the
                Influencer knows, suspects, or reasonably ought to know or
                suspect to be false, misleading, or deceptive. It must also be
                free of any indecent, discriminatory, or offensive material and
                must not promote aggressive or illegal behavior, sexually
                suggestive imagery, or violate the rights of any individual,
                corporation, or entity, including privacy rights, contract
                rights, or intellectual property rights.
              </div>
              <div>
                v. The Influencer agrees that, during the course of their
                engagement and at all times thereafter, they shall not, directly
                or indirectly, make any critical, disparaging, or negative
                statements regarding the Company, the Brand, its affiliates,
                campaigns, campaign subject matter, employees, or clients to any
                third parties, including but not limited to print or broadcast
                media.
              </div>
              <div>
                vi. The Influencer hereby provides their explicit consent to the
                Brand, its representatives, and authorized agents to contact
                them via calls, SMS, emails, or other communication channels,
                even if the Influencer is registered under any Do Not Disturb
                (DND) or similar consumer preference database. This consent is
                granted solely for the purpose of facilitating campaign
                execution and related communications.
              </div>
              <div>
                vii. The Influencer agrees that the consent provided: a) Covers
                all communications necessary for the planning, execution, or
                management of campaigns, including but not limited to approvals,
                updates, and deliverables. b) Extends to communications related
                to post-campaign matters, such as performance reviews or
                compliance discussions, if required.
              </div>
              <div>
                ix. The Influencer reserves the right to withdraw this consent
                at any time by providing written notice to the Brand. Upon
                receipt of such notice, the Brand shall cease all non-essential
                communications, except those required to fulfill any ongoing
                contractual or legal obligations.
              </div>
              <div>
                x. The Influencer acknowledges and agrees that such
                communications are integral to ensuring the smooth execution of
                campaigns. The Influencer waives any claims or liabilities
                arising solely from receiving communications that adhere to
                these terms, provided they comply with applicable laws.
              </div>
              <div>
                xi. The Influencer acknowledges and agrees that by providing
                their contact information to the Brand through the Company’s
                platform or mobile app, they explicitly permit the Brand to
                contact them, even if they are registered under Do Not Disturb
                (DND) or similar databases. The Influencer shall not hold the
                Company or the Brand liable for any claims, penalties, or
                liabilities arising from receiving such communications during
                DND mode. This indemnity applies to all calls, messages, or
                communications facilitated via the platform or mobile app,
                subject to compliance with applicable laws.
              </div>
              <div>
                xii. The Influencer shall promptly inform the Brand in writing
                of any changes to their contact details or communication
                preferences during the collaboration period.
              </div>
            </div>
            <div className="mt-5">
              <div>
                E. <FontBold text="Representations and Warranties" />
              </div>
              <div>
                i. The Influencer further represents and warrants that: (i) The
                Brand and Company may use the Influencer&#39;s name, likeness,
                and social media handles in post-campaign case studies,
                promotional content, and related materials. (ii) All information
                related to the Brand’s products and any confidential materials
                provided to the Influencer are proprietary and confidential.
                Such materials shall not be disclosed, used for personal
                purposes, or shared with third parties. Upon the Campaign going
                live, the Influencer agrees to return all confidential
                information and any copies in their possession to the Brand.
                (iii) All campaign content will be original and will not
                infringe on third-party rights; (iv) No employment or similar
                relationship exists between the Influencer and the Brand; (v)
                The Influencer will perform the Campaign as per the scheduled
                dates and brief provided by the Brand
              </div>
              <div>
                ii. The Influencer shall comply with all applicable laws and
                industry self-regulations related to influencer marketing in
                India, including but not limited to the Advertising Standards
                Council of India (ASCI) guidelines. The Influencer is also
                requested to thoroughly check the background of the Brand and
                review the relevant laws and regulations applicable to the
                Brand, especially if the Brand is based outside of India, to
                ensure compliance with international regulations.
              </div>
              <div>
                iii. The Influencer shall not alter or edit any Post once it has
                been approved by the Brand. The Brand retains the right to
                review any Campaign Deliverable after publication for legitimate
                reasons, and the Influencer shall promptly make any reasonable
                amendments as requested. The Brand and/or Company may, at their
                discretion, request the removal of any Campaign Deliverable, and
                the Influencer shall immediately comply with such request upon
                receipt. In the event of any deficiencies in the Campaign
                Deliverable, the Influencer shall promptly rectify such issues
                upon notification.
              </div>
              <div>
                iv.The Influencer shall promptly provide and sign any additional
                documents required by the Brand to ensure the proper execution
                of these Terms and Conditions. The Influencer undertakes not to
                raise any queries or seek clarifications from One Impression in
                connection with such formalities.
              </div>
            </div>
            <div className="mt-5">
              <div>
                F. <FontBold text="Code of Conduct" />
              </div>
              <div>
                The Influencer shall render services in consonance with the
                following guidelines:
              </div>
              <div>
                Clearly disclose the partnership with the brand using hashtags
                like #ad, #sponsored, #partner, or similar, in accordance with
                applicable laws.
              </div>
              <div>
                Ensure all content is accurate and factual. The Influencer is
                responsible for the content’s truthfulness and will indemnify
                the brand for false claims.
              </div>
              <div>
                Avoid making misleading or exaggerated statements about the
                brand’s products. The Influencer is responsible for any legal
                claims arising from false statements.
              </div>
              <div>
                Maintain respectful and prompt communication with the brand.
              </div>
              <div>
                Create high-quality content in line with personal style and the
                brand’s guidelines. Obtain brand approval for any significant
                changes.
              </div>
              <div>
                Adhere to copyright laws, and ensure all necessary licenses are
                obtained.
              </div>
              <div>
                Do not misuse content created for the campaign after completion,
                including reusing it without consent.
              </div>
              <div>Meet all agreed-upon deadlines for content submission.</div>
              <div>
                Respect the privacy of the audience and avoid sharing sensitive
                personal information without consent.
              </div>
              <div>
                Comply with data protection laws (e.g., GDPR) when handling user
                data and follow the brand’s privacy policies.
              </div>
              <div>
                Follow the brand’s content guidelines, including visual and
                messaging requirements.
              </div>
              <div>
                Avoid activities that could harm the brand’s reputation,
                including defamation or unethical behavior.
              </div>
              <div>
                Refrain from dishonest practices, such as buying followers or
                engagement.
              </div>
              <div>
                Foster trust-based, long-term relationships with the brand and
                disclose any conflicts of interest.
              </div>
              <div>Be reliable and professional.</div>
            </div>
            <div className="mt-5">
              <div>
                G. <FontBold text="Payment Structure" />
              </div>
              <div>
                As soon as the Campaign is confirmed, the Company shall issue a
                Purchase Order, and the payment will be made as per the terms
                stated in therein. The Influencer acknowledges and agrees that
                the Company facilitates business opportunities for Influencers
                through the Platform. The Company shall collect their
                markups/margins, directly from the Influencer, based on the
                prices quoted for Campaign on the Platform. The Influencer
                expressly confirms their understanding and acceptance of this
                payment model and irrevocably waives any right to contest,
                dispute, or question the Company&#39;s authority to charge such
                commissions at any time in the future.
              </div>
            </div>
            <div className="mt-5">
              <div>
                H. <FontBold text="Intellectual Property Rights" />
              </div>
              <div>
                The Influencer warrants that the content created will not
                infringe any third-party intellectual property rights and agrees
                to indemnify One Impression and the Brand against any claims
                arising from such infringement. The Influencer retains ownership
                of the content, granting One Impression a perpetual, irrevocable
                license to use, display, and distribute the content. The
                Influencer grants the Brand a limited, non-exclusive,
                non-transferable license to use, display, and distribute the
                content for the agreed period and purpose, after which the
                rights will expire unless otherwise agreed.
              </div>
            </div>
            <div className="mt-5">
              <div>
                I. <FontBold text="Conflict of Interest" />
              </div>
              <div>
                The Influencer represents and agrees that they are free to work
                with any brand, unless bound by a prior contract that prohibits
                such work. If the Influencer has a conflicting contract that
                restricts their ability to take up the campaign, they agree not
                to proceed and shall notify One Impression before accepting the
                campaign.
              </div>
            </div>
            <div className="mt-5">
              <div>
                J. <FontBold text="Confidentiality" />
              </div>
              <div>
                The Influencer agrees to maintain the confidentiality of all
                proprietary and confidential information disclosed by the Brand
                during the course of the engagement. The Influencer shall not,
                without the prior written consent of the Brand, disclose or use
                any such information for any purpose other than fulfilling the
                obligations under the engagement. This confidentiality
                obligation shall remain in effect even after the termination or
                expiration of the campaign.
              </div>
            </div>
            <div className="mt-5">
              <div>
                K. <FontBold text="Limitation of Liability and Indemnity" />
              </div>
              <div>
                Neither party shall be liable for any indirect, incidental, or
                consequential damages, including but not limited to loss of
                profits, even if advised of the possibility of such damages.
                This exclusion of liability shall apply regardless of the cause
                of action and shall survive the termination or expiration of the
                campaign. The Influencer shall indemnify, defend, and hold
                harmless One Impression and the Brand, its officers, directors,
                parent, subsidiaries, and affiliates from any and all claims,
                liabilities, damages, losses, costs, and expenses arising from
                any breach of engagement, third-party claims, including but not
                limited to intellectual property infringement, defamation,
                violations of applicable laws, misrepresentation, fraud, gross
                negligence, or wilful misconduct by the Influencer. In no event
                shall One Impression&#39;s total liability to the Influencer
                exceed INR 10,000. One Impression shall promptly notify the
                Influencer of any claim or litigation to which the indemnity set
                forth herein applies.
              </div>
            </div>
            <div className="mt-5">
              <div>
                L. <FontBold text="Support Team" />
              </div>
              <div>
                The Company shall enable automated delivery of campaign for the
                ease of both Brands and influencers through the Platform. This
                process will include real-time updates and progress tracking for
                campaigns. The Platform will facilitate direct 1:1 communication
                between Brands and influencer where brands can send text
                messages, voice notes, and initiate calls with influencer via
                the Platform. Such communication features are intended solely
                for campaign- related discussions. In case of disputes or
                unresolved queries between the Brand and the influencer, either
                party may contact the Company&#39;s support team at
                support@oneimpression.io
              </div>
            </div>
          </div>
        </div>
      </div>
    </TermsAndConditionsLayout>
  );
};
